import Heading from "@/components/elements/Heading";
import VerticalCard from "@/components/elements/VerticalCard";

import * as styles from "./styles.module.scss";

interface ScientificOtherAuthorsQuery {
  authorsList: {
    key: string;
    title: string;
    excerpt: string;
    url: string;
    slug: string;
    heroImage: StoryblokGatsbyImageAsset | undefined;
  }[];
}

export function ScientificOtherAuthors({
  authorsList,
}: ScientificOtherAuthorsQuery) {
  return (
    <section className={styles.section}>
      <div className="container">
        <Heading level={2}>Other members of the Scientific Board</Heading>
        {authorsList.length > 0 && (
          <ul className={styles.cardsList}>
            {authorsList.map((author) => (
              <li key={author.url}>
                <VerticalCard
                  url={author.url}
                  title={author.title}
                  excerpt={author.excerpt}
                  heroImage={author.heroImage}
                  className={styles.authorCard}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
}
