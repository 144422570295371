import LinkComponent from "@/components/elements/LinkComponent";
import Heading from "@/components/elements/Heading";
import Image from "@/components/elements/Image";
import RichText from "@/components/elements/RichText"

import ClipPath from "@/components/elements/ClipPath";
import * as styles from "./styles.module.scss";

// eslint-disable-next-line import/no-unresolved
import "/node_modules/flag-icons/css/flag-icons.min.css";

interface ScientificAuthorHeroProps
  extends Pick<
    ScientificAuthorPageTemplate,
    | "sa_name"
    | "sa_description"
    | "sa_position"
    | "sa_author_image"
    | "sa_testimonial"
    | "sa_rg_link"
  > {
  countryData?: DataSourceEntry<CountryType>;
}

export function ScientificAuthorHero({
  sa_name,
  sa_description,
  sa_position,
  sa_testimonial,
  countryData,
  sa_author_image,
  sa_rg_link,
}: ScientificAuthorHeroProps) {
  const renderAuthorCountryFlag = (flag: string) => {
    if (typeof flag === "string") {
      return <span style={{ padding: 10 }} className={`fi fi-${flag}`}></span>;
    }

    return "";
  };

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.headerContainer}>
          <LinkComponent
            type="internal"
            url="/scientific-board/"
            className={styles.subtitle}
          >
            Scientific Board /
          </LinkComponent>
          <Heading level={1}>{sa_name}</Heading>
          <p className={styles.position}>{sa_position}</p>
          {countryData && (
            <div className={styles.country}>
              <div className={styles.flag}>
                {renderAuthorCountryFlag(countryData.value)}
              </div>
              <p>{countryData.name}</p>
            </div>
          )}
          <img src="/svgs/leafAuthor.svg" alt="leaf" className="leaf" />
          <img src="/svgs/seeds/almond.svg" alt="leaf" className="seed" />
        </div>
        <div className={styles.authorContent}>
          <div className={styles.authorImage}>
            {sa_author_image?.image && (
              <ClipPath version={3}>
                <Image fluid data={sa_author_image} alt={sa_name} />
              </ClipPath>
            )}
          </div>
          <div className={styles.authorDescription}>
            <p className={styles.description}>{sa_description}</p>
            {Array.isArray(sa_rg_link) &&
              sa_rg_link.map((item) => (
                <LinkComponent url={item.url} type={item.type} key={item.url}>
                  {item.title}
                </LinkComponent>
              ))}
          </div>
        </div>
      </div>
      {sa_testimonial && (
        <div className={styles.testimonial}>
          <RichText content={sa_testimonial} />
        </div>
      )}
    </section>
  );
}
