// extracted by mini-css-extract-plugin
export var authorContent = "styles-module--author-content--931d9";
export var authorDescription = "styles-module--author-description--ac81b";
export var authorImage = "styles-module--author-image--75c4a";
export var country = "styles-module--country--c7a21";
export var description = "styles-module--description--55181";
export var flag = "styles-module--flag--a45f1";
export var headerContainer = "styles-module--header-container--89477";
export var position = "styles-module--position--6c021";
export var section = "styles-module--section--aa3da";
export var subtitle = "styles-module--subtitle--4d930";
export var testimonial = "styles-module--testimonial--2a079";